import { images } from "../constants";

export const dataCourses = [
  {
    id: 1,
    title: "Software Engineering",
    description:
      "Our engineering programs are unique and provide students with real-life experiences that help them develop the knowledge and skills required to kick start their careers, and compete favorably with their peers worldwide. To ensure this happens, we have incorporated active learning to allow for the application of theory to practice. There’s also a focus on applied research where technology is utilized to solve actual problems. Ready to begin your journey as a Software Engineer?",
    image: images.web,
  },
  {
    id: 2,
    title: "Digital Marketing",
    description:
      "Our digital marketing program teaches you all the skills that your employer needs and will make you a future leader  in the marketing field. We focus on teaching real-worldskills required to be a successful digital marketer. With our help, you can begin to build your professional portfolio by reaching out to different types of clients, including corporate, small business, or startup businesses. Our focus includes Search Engine Optimization, Search Engine Marketing, Video Marketing, Advertising Marketing, Social Media Marketing, and Content Marketing",
    image: images.digital,
  },
];

export default dataCourses;
