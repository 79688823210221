import React, { useEffect, useState, Fragment } from "react";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import { FaAngleUp } from "react-icons/fa";
import { images } from "../constants";
import { Link } from "react-router-dom";
import dataCourses from "../data/AllCourse";

const About = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Fragment>
      <div style={{ backgroundColor: "#000" }}>
        <main className="page-aboutUs">
          <header>
            <div className="hero-container">
              <div className="hero-text" style={{ marginTop: "40px" }}>
                <div>
                  <h2 style={{ textAlign: "center" }}>
                    The Narrative of SAMDAN Tech School
                  </h2>
                </div>
                <div>
                  <p className="p-3" style={{ textAlign: "left" }}>
                    Our programs are designed to be hands-on and practical,
                    giving students the opportunity to learn by doing and gain
                    real-world experience. We have a dedicated faculty of
                    industry professionals who are passionate about sharing
                    their knowledge and expertise with our students.
                  </p>
                </div>
              </div>
            </div>
          </header>
        </main>
      </div>

      <div style={{ backgroundColor: "#fff" }}>
        <main className=" text-dark container">
          <h4 className="text-center mt-5 text-uppercase text-uppercase fw-bolder text-center">
            About Us
          </h4>
          <section className="about-page">
            <div>
              <img
                src={images.About}
                className="img-fluid app__img-about"
                alt=""
              />
            </div>

            <article>
              <p
                className="p-4"
                style={{ textAlign: "justify", fontSize: "19px" }}
              >
                Having grown up in the African most populous nation with a
                staggering 9.7 percent unemployment rate, it’s not uncommon to
                see potentials being wasted and teenagers spend their time
                searching for jobs and falling into petty crimes to create
                income while their parents are left doing nothing all because of
                financial crisis. These are subjects of concern for a patriotic
                entrepreneur. And if poverty must be abated in a society, the
                people must be equipped with the skills that can solve human
                problems. So as entrepreneurs who are passionate about making a
                social impact, we decided to found an academy that will train
                young individuals in-demand tech skills and prepare them for the
                dynamic job environment of tech. Our ultimate goal is to have
                talented individuals who can benefit from this learning
                experience, excel in their careers and make a difference in the
                world.
              </p>
            </article>
          </section>
        </main>
      </div>

      <div className="container mt-5">
        <div className="row page">
          <h4 className="text-uppercase fw-bolder text-center mb-5">
            {" "}
            Who is SAMDAN TECH for?
          </h4>
          <div className="col-sm-6">
            <h5>Want your child to learn to code or market?</h5>
            <p>
              Our academy has developed an exciting and innovative curriculum
              that is perfect for kids from any background or academic
              abilities.
            </p>

            <h5>Wants to change career? We are here for you</h5>
            <p>
              We believe that Transforming careers is one of the most powerful
              things you can do for yourself and your family. We are here to
              support you in your quest to change careers with the speed and
              skill you need on the right path, with an accelerated curriculum
              that accommodates your schedules.
            </p>

            <h5>
              Want better opportunities in tech? You are at the surest place.
            </h5>
            <p>
              Our curriculum is carefully designed to teach you the skills
              employers are most looking for so that you can excel in today’s
              talent market. Learn from our experienced instructors, who share
              their insights, innovative materials, and hands-on experiences
              that help you prepare for success.
            </p>

            <h5>Too busy? You are considered.</h5>
            <p>
              Our courses and curriculum are designed to fit your life and work
              around your needs. We have designed it in a way that you can
              progress at your own pace and with flexibility.
            </p>
          </div>
          <div className="col-sm-6">
            <p className="text-uppercase about__vertical text-white mb-5"></p>
          </div>
        </div>
      </div>

      <div id="course" style={{ backgroundColor: "#fff" }}>
        <main className="page mt-5 mb-5 text-dark">
          <h4 className="text-uppercase fw-bolder text-center mb-5">
            FIND THE RIGHT COURSES FOR YOU <br /> <br />
            Schools for you
          </h4>
          <p></p>
          <section>
            <CardGroup>
              {dataCourses.map((data) => (
                <Card key={data.id} className="img-wrapper text-dark">
                  <Card.Img
                    className="hover-zoom"
                    variant="top"
                    src={data.image}
                  />
                  <Card.Body>
                    <Card.Title style={{ color: "#27AAE2" }} className="mt-5">
                      {/* Software engineering */}
                      {data.title}
                    </Card.Title>
                    <Card.Text style={{ textAlign: "justify" }}>
                      {data.description}
                    </Card.Text>
                  </Card.Body>

                  <Link
                    to="/apply"
                    className="btn btn-hover w-100 border-0"
                    style={{ cursor: "pointer", border: "none" }}
                  >
                    Apply Now
                  </Link>
                </Card>
              ))}
            </CardGroup>
          </section>
        </main>
      </div>
      <div className="top-to-btm">
        {" "}
        {showTopBtn && (
          <FaAngleUp className="icon-position icon-style" onClick={goToTop} />
        )}{" "}
      </div>
    </Fragment>
  );
};

export default About;
