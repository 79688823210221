import React, { Fragment } from "react";
import { images } from "../constants";

const Footer = () => {
  return (
    <Fragment>
      <footer>
        <div
          style={{ backgroundColor: "#000" }}
          className="container-fluid footer"
        >
          <div className="row">
            <div className="col-sm-6 text-white">
              <img
                className="no-background_logo img-fluid"
                src={images.logo}
                alt=""
              />
            </div>

            <div className="col-sm-6 p-5 follow text-white">
              <h5 className="font-weight-bold text-white">FOLLOW US</h5>
              <a
                href="https://web.facebook.com/profile.php?id=100093470017915"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-facebook-square p-2 fa-1x text-white"></i>
              </a>
              <a href="##" target="_blank" rel="noreferrer">
                <i className="fab fa-twitter fa-1x p-2 text-white"></i>
              </a>
              <a
                href="https://www.instagram.com/invites/contact/?i=17bi0078zj1l1&utm_content=rl20ty4"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-instagram fa-1x p-2 text-white"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/scmas/"
                target="_blank"
                rel="noreferrer"
                // https://www.linkedin.com/company/scmas/</div>
              >
                <i className="fab fa-linkedin fa-1x p-2 text-white"></i>
              </a>
            </div>

            <div>
              <p className="text-white text-center">
                COPYRIGHT &copy; 2022 SAMDAN. ALL RIGHTS RESERVED
              </p>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
};

export default Footer;
