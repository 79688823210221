import React, { useEffect, useState, Fragment } from "react";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import Typed from "react-typed";
import { FaAngleUp } from "react-icons/fa";
import { images } from "../constants";
import { Link } from "react-router-dom";
import dataCourses from "../data/AllCourse";
// import { Example } from "./Slide";

const Home = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Fragment>
      <div className="app__banner-img">
        <main className="page">
          <header>
            <div className="hero-container">
              <div className="hero-text" style={{ marginTop: "50px" }}>
                <div>
                  <h2
                    style={{
                      textAlign: "center",
                      color: "#FFF",
                      fontWeight: "bold",
                    }}
                  >
                    Kickstart your tech career in{" "}
                  </h2>
                  <h2>
                    <Typed
                      strings={[
                        " Product Design",
                        " Web Development",
                        " Data Anaylsis",
                        " Digital Marketing",
                      ]}
                      style={{ color: "#14257D", fontWeight: "bold" }}
                      typeSpeed={70}
                      backSpeed={70}
                      loop
                    />
                  </h2>
                </div>
                <div>
                  <p className="p-3" style={{ textAlign: "left" }}>
                    {/* Learn the in-demand skills required to take you from
                    beginner to industry ready in 12 months. No degree or prior
                    tech experience required. */}
                    Learn and start a Life-Changing Career in the Most In-Demand
                    Tech Roles in 12 months or less. Be challenged. Be
                    supported.
                  </p>
                </div>
              </div>
            </div>
          </header>
        </main>
      </div>

      <main className="page mt-5">
        <h4 className="text-uppercase fw-bolder text-center mb-5">
          WHAT IS SAMDAN TECH SCHOOL?
        </h4>
        <section className="about-page app__know">
          <article>
            <h2 className="lh-base app__equip">
              Equipping African young Adults for the tech jobs of today and the
              future.
            </h2>
            <p>
              We believe that young Africans can compete well on the global
              stage if they have more opportunities to show their potential.
              SAMDAN, unlike traditional institutions that teach theories, is an
              academy for learning in-demand digital Skills. We believe that
              there are many skills not being learned in school and this is
              where SAMDAN steps in to bridge the skill gaps among teenagers and
              young adults.
            </p>
            <p>
              We believe that inventing and learning new things make humans
              young, happy, and successful. We are proud to be here in this
              industry, helping young demigods learn skills that make them more
              productive throughout their lives.
            </p>
            <Link to="/about" className="btn btn-hover">
              {" "}
              Know More{" "}
            </Link>
          </article>
          <img
            src={images.sectionImg}
            alt="Person Pouring Salt in Bowl"
            className="img about-img img-fluid"
          />
        </section>
      </main>

      <div style={{ backgroundColor: "#0C111B" }}>
        <main className="page mt-5 text-white">
          <h4 className="text-uppercase fw-bolder text-center mb-5">
            Who is SAMDAN academy for?
          </h4>
          <section className="about-page">
            <div>
              <img
                src={images.girl}
                alt="guy"
                class="img-fluid app__girl-img"
              />
            </div>

            <article>
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <h5>
                      Hands-on training with the latest technology and software
                    </h5>
                    <p>
                      At SAMDAN Tech School, we believe in the power of
                      technology to solve problems and drive innovation. That's
                      why we're committed to providing students with the skills,
                      knowledge, and experience they need to succeed in today's
                      tech-driven world. From software development to design and
                      digital marketing, our programs are designed to give
                      students the edge they need to excel in their chosen
                      careers.
                    </p>
                  </div>
                </div>
              </div>

              <a href="#course" className="btn btn-hover">
                {" "}
                View Courses
              </a>
            </article>
          </section>
        </main>
      </div>

      <div id="course" style={{ backgroundColor: "#fff" }}>
        <main className="page mt-5 text-dark mb-5">
          <h4 className="text-uppercase fw-bolder text-center mb-5">
            FIND THE RIGHT COURSES FOR YOU <br /> <br />
            Schools for you
          </h4>
          <p></p>
          <section>
            <CardGroup>
              {dataCourses.map((data) => (
                <Card key={data.id} className="img-wrapper text-dark mb-5">
                  <Card.Img
                    className="hover-zoom"
                    variant="top"
                    src={data.image}
                  />
                  <Card.Body>
                    <Card.Title style={{ color: "#27AAE2" }} className="mt-5">
                      {/* Software engineering */}
                      {data.title}
                    </Card.Title>
                    <Card.Text style={{ textAlign: "justify" }}>
                      {data.description}
                    </Card.Text>
                  </Card.Body>

                  <Link
                    to="/apply"
                    className="btn btn-hover w-100 border-0"
                    style={{ cursor: "pointer", border: "none" }}
                  >
                    Apply Now
                  </Link>
                </Card>
              ))}
            </CardGroup>
          </section>
        </main>
      </div>
      <div className="top-to-btm">
        {" "}
        {showTopBtn && (
          <FaAngleUp className="icon-position icon-style" onClick={goToTop} />
        )}{" "}
      </div>
    </Fragment>
  );
};

export default Home;
