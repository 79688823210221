// import logo from "../assets/images/logo.svg";
import sectionImg from "../assets/images/main_rest.jpeg";
import girl from "../assets/images/girl.jpg";
import product from "../assets/images/product_image.jpg";
import web from "../assets/images/web.jpg";
import digital from "../assets/images/digital.jpg";
import About from "../assets/images/about.jpg";
import Little from "../assets/images/little.jpg";
// import logo_mas from "../assets/images/logos.jpeg";
import logo from "../assets/images/logor.png";

const allImages = {
  sectionImg,
  girl,
  product,
  web,
  digital,
  About,
  Little,
  logo,
};

export default allImages;
