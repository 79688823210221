import React, { Fragment, useState } from "react";
import { images } from "../constants";
import { Link } from "react-router-dom";
import "../assets/scss/main.scss";

const Header = () => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(false);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  return (
    <Fragment>
      {/* nav  */}
      <nav className="navbar navbar-expand-lg" style={{ marginTop: "-20px" }}>
        <div className="nav-center app_all" id="fixed">
          <div className="nav-header">
            <Link to="/" className="nav-logo">
              {/* <img src={images.logo} alt="simply recipes" /> */}

              <img
                className="no-background"
                style={{ marginLeft: "-50px" }}
                src={images.logo}
                alt=""
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={handleNavCollapse}
            >
              <i
                className={
                  isNavCollapsed ? "fas fa-times togg" : "fas fa-bars togg"
                }
              />
            </button>
          </div>

          <div
            className={`${
              isNavCollapsed ? "collapse" : ""
            } collapse navbar-collapse app__nav`}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav me-auto mt-4">
              <li className="nav-item">
                <Link
                  to="/"
                  className="nav-link active"
                  onClick={handleNavCollapse}
                >
                  Home
                </Link>
              </li>
              <a href="#course" className="nav-link">
                {" "}
                Course{" "}
              </a>

              <li className="nav-item">
                <Link
                  to="/about"
                  className="nav-link"
                  onClick={handleNavCollapse}
                >
                  {" "}
                  About Us{" "}
                </Link>
              </li>

              <li className="nav-item contact-link">
                <Link
                  to="/apply"
                  className="btn text-white"
                  onClick={handleNavCollapse}
                >
                  {" "}
                  Apply now{" "}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/*  end of nav  */}
    </Fragment>
  );
};

export default Header;
