import React, { useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
import "../assets/scss/apply.scss";

function Apply() {
  const navigate = useNavigate();
  // // const MySwal = withReactContent(Swal);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    program: "",
  });

  const { firstName, lastName, email, phoneNumber, program } = data;

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setIsSubmitting(true);
  //   try {
  //     const res = await fetch(
  //       `https://v1.nocodeapi.com/shotin/google_sheets/IsCScRYKcZTMlVnF?tabId=Sheet1`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify([
  //           [
  //             firstName,
  //             lastName,
  //             email,
  //             phoneNumber,
  //             program,
  //             new Date().toLocaleString(),
  //           ],
  //         ]),
  //       }
  //     );
  //     await res.json();
  //     setData({
  //       ...data,
  //       firstName: "",
  //       lastName: "",
  //       email: "",
  //       phoneNumber: "",
  //       program: "",
  //     }).then((data) => {
  //       toast.success(data.message, {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "dark",
  //       });
  //     });
  //     // Navigate("/");
  //     setIsSubmitting(false);
  //   } catch (response) {
  //     console.log(response);
  //   }
  //   setIsSubmitting(false);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const res = await fetch(
        `https://v1.nocodeapi.com/shotin/google_sheets/IsCScRYKcZTMlVnF?tabId=Sheet1`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify([
            [
              firstName,
              lastName,
              email,
              phoneNumber,
              program,
              new Date().toLocaleString(),
            ],
          ]),
        }
      );
      await res.json();
      setData({
        ...data,
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        program: "",
      });
      toast.success("Registration successfully.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setIsSubmitting(false);
    } catch (error) {
      console.log(error);
      toast.error("Please try again.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setIsSubmitting(false);
    }
  };
  return (
    <Fragment>
      <main className="container page mb-5">
        <h4 className="text-uppercase fw-bolder text-center app__equip">
          Join Our Waitlist
        </h4>
        {/* <button onClick={notify}>Notify!</button> */}
        {/* <ToastContainer /> */}
        <section className="row app p-5">
          <form className="form_submit" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="firstName" className="form-label">
                First Name
              </label>
              <input
                type="text"
                name="firstName"
                placeholder="John"
                className="form-control p-3"
                autoComplete="off"
                required
                value={firstName}
                onChange={handleChange}
                // {...register("firstname")}
              />
            </div>
            <div>
              <label htmlFor="lastName" className="form-label">
                Last Name
              </label>
              <input
                type="text"
                placeholder="Doe"
                name="lastName"
                className="form-control p-3"
                required
                value={lastName}
                onChange={handleChange}
                // {...register("lastname")}
              />
            </div>
            <div>
              <label htmlFor="email" className="form-label">
                Email address
              </label>
              <input
                type="email"
                name="email"
                placeholder="xyz@gmail.com"
                className="form-control p-3"
                required
                value={email}
                onChange={handleChange}
                // {...register("email")}
              />
            </div>

            <div>
              <label htmlFor="phoneNumber" className="form-label">
                Phone Number
              </label>
              <input
                type="number"
                min="1"
                name="phoneNumber"
                placeholder="08012345678"
                className="form-control p-3"
                required
                value={phoneNumber}
                onChange={handleChange}
                // {...register("phonenumber")}
                onInput={(e) => (e.target.value = e.target.value.slice(0, 11))}
              />
            </div>

            {/* <PhoneInputWithCountry
              name="phoneInputWithCountrySelect"
              control={control}
              rules={{ required: true }}
              className="form-control p-3"
            />  */}
            <div>
              <label htmlFor="program" className="form-label">
                Choose a program
              </label>
              <select
                className="form-select p-3"
                aria-label="Default select example"
                name="program"
                value={program}
                onChange={handleChange}
                // {...register("program")}
              >
                <option value="Software Development">
                  Software Development
                </option>
                <option value="Product Design">Product Design</option>
                <option value="Digital Marketer">Digital Marketer</option>
              </select>
            </div>

            <div className="mb-3">
              <p>
                By signing up, I understand and agree to SAMDAN{" "}
                <Link to="/terms">Terms of Service</Link>
              </p>
            </div>

            <span>
              <button type="submit" className="btn btn-primary btn-hover">
                {isSubmitting && (
                  <Spinner size="sm" animation="border" role="status">
                    <span className="visually-hidden fa-2x"></span>
                  </Spinner>
                )}
                Submit
              </button>
            </span>
          </form>
        </section>
      </main>
    </Fragment>
  );
}

export default Apply;
