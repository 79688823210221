import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Header from "./pages/Header";
import Footer from "./pages/Footer";
import Apply from "./pages/Apply";
import About from "./pages/About";
import { ToastContainer } from "react-toastify";
import NotFound from "./pages/NotFound";
// import { Example } from "./pages/Slide";

function App() {
  return (
    <Fragment>
      <Header NotFound={NotFound} />
      {/* <Example /> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/apply" element={<Apply />} />
        <Route path="/about" element={<About />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer theme="colored" />
      <Footer />
    </Fragment>
  );
}

export default App;
