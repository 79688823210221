import { Fragment } from "react";
import "../assets/scss/notfound.scss";

export default function NotFound({ NotFound }) {
  return (
    <Fragment>
      <div className="not">
        <h1>404 | Oops! You seem to be lost.</h1>
      </div>
    </Fragment>
  );
}
